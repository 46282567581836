import React, { useLayoutEffect } from 'react'
import Banner from './banner'
import Values from './values'
import Form from './form'
import './careers.css'

import ScrollToPlugin from 'gsap/ScrollToPlugin'
import gsap from 'gsap'

gsap.registerPlugin(ScrollToPlugin)

export default function Careers() {
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(window, { scrollTo: 0 })
    })

    return () => ctx.revert()
  }, [])

  return (
    <>
      <Banner />
      <Values />
      <Form />
    </>
  )
}
